import React, { useRef } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { message, Spin, List, Input, Skeleton, Divider, Button, Space, DatePicker } from 'antd';
import { useEffect, useState } from "react";
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroll-component';
import { CUSTOMER_CHECK_IN_STATUS_VALUES, displayDateTimeFormat, OPEN_CHATS_BY_COUNT, OPEN_CHATS_BY_MINUTES, SALE_STAGE_CONVERSATIONS_FILTERING_VALUES, SALE_STAGE_FILTERING_VALUES } from "../../global_constants"
import { Form, Select } from "antd";

import "react-chat-elements/dist/main.css";
import { ChatItem } from "react-chat-elements";

import { getAllCallRailTextConversations } from "../../actions";
import { checkIfArrayTypeFilterIsSet, checkIfStringTypeFilterIsSet } from '../../utilities/queryString';
import debounce from 'lodash/debounce';
import { localToUtc } from '../../helpers';

const defaultPagination = {
  current: 1,
  pageSize: 50,
}

const { RangePicker } = DatePicker;

const ChatMessagesListComponent = ({
  onClickChatItem,
  unacknowledgedConversations = []
}) => {
  const [conversationsLoading, setConversationsLoading] = useState(false);
  const [conversationsTablePagination, setConversationsTablePagination] = useState(defaultPagination);

  const [isChatContactsLoading, setIsChatContactsLoading] = useState(false);

  const [conversations, setConversations] = useState([]);
  const [filterForm] = Form.useForm();
  const [filters, setFilters] = useState({});
  const [openByMinutes, setOpenByMinutes] = useState(OPEN_CHATS_BY_MINUTES)
  const [openByCounts, setOpenByCounts] = useState(OPEN_CHATS_BY_COUNT)
  const [selectedMinutes, setSelectedMinutes] = useState(0);
  const [selectedCounts, setSelectedCounts] = useState(0);
  const [userDefinedOpenByMinutes, setUserDefinedOpenByMinutes] = useState('');
  const [userDefinedOpenByCounts, setUserDefinedOpenByCounts] = useState('');
  const userDefinedOpenByMinutesRef = useRef(null);
  const userDefinedOpenByCountsRef = useRef(null);

  const searchConversationsForFilterValue = () => {
    loadAllConversations();
  }

  const refreshConversationList = () => {
    loadAllConversations();
  }

  const openChatByCount = () => {
    if (selectedCounts) {
      const chatsToOpen = conversations.slice(0, selectedCounts);
      chatsToOpen.forEach(onClickChatItem);
    }
  };

  const openChatsByMinutes = () => {
    if (selectedMinutes) {
      const timeThreshold = moment.utc().subtract(selectedMinutes, 'minutes');
      const chatsToOpen = conversations.filter(chat => moment.utc(chat.last_message_at) > timeThreshold);
      chatsToOpen.forEach(onClickChatItem);
    }
  };

  const addOptionToOpenByMinutes = (e) => {
    e.preventDefault();

    if (!(/\d+/.test(userDefinedOpenByMinutes))) {
      message.error('Please enter a valid number')
      return
    }

    const value = Number(userDefinedOpenByMinutes)

    if (openByMinutes.indexOf(value) > -1) {
      message.error('Value already present in the list')
      return
    }

    setOpenByMinutes([...openByMinutes, value])
    setUserDefinedOpenByMinutes('');
    setTimeout(() => {
      userDefinedOpenByMinutesRef.current?.focus()
    }, 0)
  };

  const addOptionToOpenByCounts = (e) => {
    e.preventDefault();

    if (!(/\d+/.test(userDefinedOpenByCounts))) {
      message.error('Please enter a valid number')
      return
    }

    const value = Number(userDefinedOpenByCounts)

    if (openByCounts.indexOf(value) > -1) {
      message.error('Value already present in the list')
      return
    }

    setOpenByCounts([...openByCounts, value])
    setUserDefinedOpenByCounts('');
    setTimeout(() => {
      userDefinedOpenByCountsRef.current?.focus()
    }, 0)
  };

  const onFilterFormChange = (_, values) => {
    localStorage.setItem('chat_messages_list_filters', JSON.stringify(values))
    searchConversationsForFilterValue()
  }

  const getQueryParams = () => {
    let search_term = filterForm.getFieldValue('search_term')
    let sale_stages = filterForm.getFieldValue('sale_stages')
    let customer_checked_in_statuses = filterForm.getFieldValue('customer_checked_in_statuses')
    let chat_by_date_time = filterForm.getFieldValue('chat_by_date_time');

    let start_date, end_date;
    if (chat_by_date_time && chat_by_date_time.length === 2) {
      start_date = localToUtc(chat_by_date_time[0]).format();
      end_date = localToUtc(chat_by_date_time[1]).format();
    }

    const params = new URLSearchParams({
      ...(checkIfStringTypeFilterIsSet(search_term)) && { search_term },
      ...(checkIfArrayTypeFilterIsSet(sale_stages)) && { sale_stages },
      ...(checkIfArrayTypeFilterIsSet(customer_checked_in_statuses)) && { customer_checked_in_statuses },
      ...(checkIfStringTypeFilterIsSet(start_date)) && { start_date },
      ...(checkIfStringTypeFilterIsSet(end_date)) && { end_date },
    }).toString();

    console.log("params", params)

    if (params === null)
      setIsChatContactsLoading(true);
    return params;
  }

  const loadAllConversations = (page = null, pageSize = null) => {

    const params = getQueryParams();

    if (page === null) page = defaultPagination.current;
    if (pageSize === null) pageSize = defaultPagination.pageSize;

    getAllCallRailTextConversations(page, pageSize, params).request.then((response) => {
      const { data } = response;
      const { model, pagination } = data;
      setConversations(model);
      setConversationsTablePagination(pagination);
      if (params === null) setIsChatContactsLoading(false);
    }).catch((err) => {
      if (params === null) setIsChatContactsLoading(false);
      message.error(err?.response?.data?.detail);
    });
  }

  const loadMoreConversations = () => {
    if (conversationsLoading) {
      return;
    }
    setConversationsLoading(true);

    const params = getQueryParams();

    getAllCallRailTextConversations(conversationsTablePagination.current + 1, conversationsTablePagination.pageSize, params).request.then((response) => {
      const { data } = response;
      const { model, pagination } = data;

      // only add conversations not present in the list
      // we do this because there could be issue with checking for new messages
      setConversations((curr) => {
        const currentPresentConversationIds = curr.map(x => x.id);
        const newConversations = model.filter(x => !currentPresentConversationIds.includes(x.id))
        return [...curr, ...newConversations];
      });
      setConversationsTablePagination(pagination);
      setConversationsLoading(false);
    }).catch((err) => {
      setConversationsLoading(false);
      message.error(err?.response?.data?.detail);
    });
  };

  const checkNewMessagesOnly = () => {
    // checks and inserts in presence of new conversations
    // doesn't update the existing list of conversations in terms of last message or anything
    // just checks and inserts new conversations
    // that's why it's forced to load the first page and limit to 10
    // it is highly unlikely that more than 10 will be created between interval (3 seconds)
    const params = getQueryParams();

    getAllCallRailTextConversations(1, 10, params).request.then((response) => {
      const { data } = response;
      const { model, pagination } = data;
      // add conversations not present at the beginning of the list
      // update the total 
      setConversations((curr) => {
        const currentPresentConversationIds = curr.map(x => x.id);
        const newConversations = model.filter(x => !currentPresentConversationIds.includes(x.id))
        return [...newConversations, ...curr];
      });
      setConversationsTablePagination((curr) => {
        return { ...curr, total: pagination.total }
      });
      setConversationsLoading(false);
    }).catch((err) => {
      setConversationsLoading(false);
      message.error(JSON.stringify(err?.response?.data?.detail || "Something went wrong while refreshing messages! Please refresh or contact the developer"));
    });
  }

  useEffect(() => {
    searchConversationsForFilterValue();

    let intervals = [];
    const interval = setInterval(() => {
      checkNewMessagesOnly();
    }, 3000);
    intervals.push(interval);

    return () => {
      intervals.forEach(interval => {
        clearInterval(interval)
      });
    }
  }, []);

  const [saleStageSelectedValue, setSaleStageSelectedValue] = useState([]);

  const saleStageValueHandleChange = value => {
    if (value.includes("NoJob")) {
      setSaleStageSelectedValue(["NoJob"]);
    } else {
      setSaleStageSelectedValue(value.filter(item => item !== "NoJob"));
    }
  };

  return (
    <>
      {/* Apply absolute positioning to the button using the class "chat-refresh-button" to align it parallel to the chats label. */}
      <div className='display-block clearfix chat-refresh-button'>
        <Button
          style={{ float: "right" }}
          type="link"
          onClick={refreshConversationList}
        >
          Refresh
        </Button>
      </div>
      <Form
        className='display-block'
        form={filterForm}
        onValuesChange={debounce(onFilterFormChange, 850)}
        initialValues={filters}
      >
        <Form.Item
          name='search_term'
          label='Search Term'
        >
          <Input
            placeholder={"Search by name or phone#"}
          />
        </Form.Item>
        <Form.Item
          name='sale_stages'
          label='Sale Stage(s)'
        >
          <Select
            mode="multiple"
            placeholder="Select Sale Stages"
            allowClear
            value={saleStageSelectedValue}
            onChange={saleStageValueHandleChange}
          >
            {SALE_STAGE_CONVERSATIONS_FILTERING_VALUES.map(item => (
              <Select.Option
                disabled={item.value === "NoJob" ? saleStageSelectedValue.length > 0 && !saleStageSelectedValue.includes("NoJob") : saleStageSelectedValue.includes("NoJob")}
                key={item.value}
                value={item.value}
              >
                {item.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name='customer_checked_in_statuses'
          label='Check In Status'
        >
          <Select
            mode="multiple"
            placeholder="Select Check In Statuses"
            allowClear
          >
            {CUSTOMER_CHECK_IN_STATUS_VALUES.map(item => (
              <Select.Option key={item.value} value={item.value}>
                {item.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <div>
          <small>
            <i>Newly added values are not persisted, they disappear after a reload</i>
          </small>
        </div>
        <Form.Item>
          <div className='flex'>
            <Select
              placeholder="Open chats by count"
              allowClear
              onChange={(val) => setSelectedCounts(val)}
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Divider style={{ margin: '8px 0' }} />
                  <Space style={{ padding: '0 8px 4px' }}>
                    <Input
                      placeholder="Please enter item"
                      ref={userDefinedOpenByCountsRef}
                      value={userDefinedOpenByCounts}
                      onChange={(e) => { setUserDefinedOpenByCounts(e.target.value) }}
                      onKeyDown={(e) => e.stopPropagation()}
                    />
                    <Button type="text" icon={<PlusOutlined />} onClick={addOptionToOpenByCounts}></Button>
                  </Space>
                </>
              )}
              options={openByCounts.map((item) => ({
                label: item,
                value: item,
              }))}
            />
            <Button
              type="link"
              onClick={openChatByCount}
            >
              Open Chats
            </Button>
          </div>
        </Form.Item>
        <Form.Item>
          <div className='flex'>
            <Select
              placeholder="Open chats by minutes"
              allowClear
              onChange={(val) => setSelectedMinutes(val)}
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Divider style={{ margin: '8px 0' }} />
                  <Space style={{ padding: '0 8px 4px' }}>
                    <Input
                      placeholder="Please enter item"
                      ref={userDefinedOpenByMinutesRef}
                      value={userDefinedOpenByMinutes}
                      onChange={(e) => { setUserDefinedOpenByMinutes(e.target.value) }}
                      onKeyDown={(e) => e.stopPropagation()}
                    />
                    <Button type="text" icon={<PlusOutlined />} onClick={addOptionToOpenByMinutes}></Button>
                  </Space>
                </>
              )}
              options={openByMinutes.map((item) => ({
                label: item,
                value: item,
              }))}
            />
            <Button
              type="link"
              onClick={openChatsByMinutes}
            >
              Open Chats
            </Button>
          </div>
        </Form.Item>
        <Form.Item
          name='chat_by_date_time'
          label='Date & Time'
        >
          <RangePicker
            showTime={{ use12Hours: true, format: "hh:mm a" }}
            format={displayDateTimeFormat}
          />
        </Form.Item>
      </Form>
      <div
        className={"scrollable-container"}
        id={"contactListScrollLoadMore"}
      >
        <div>
          <If condition={isChatContactsLoading}>
            <Spin />
            <Else />
            <InfiniteScroll
              dataLength={conversations.length}
              next={loadMoreConversations}
              hasMore={conversations.length < (conversationsTablePagination.total || 0)}
              loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
              endMessage={<Divider plain>All Messages have been loaded</Divider>}
              scrollableTarget="contactListScrollLoadMore"
            >
              <List
                dataSource={conversations}
                renderItem={conversation => (
                  <ChatItem
                    id={conversation.id}
                    key={conversation.id}
                    avatar={"https://i.imgur.com/MQGUWRX.png"}
                    alt={"Conversation with " + conversation.customer_phone_number}
                    title={conversation.customer_formatted_name}
                    subtitle={conversation.customer_phone_number}
                    date={moment.utc(conversation.last_message_at).local().toDate()}
                    unread={unacknowledgedConversations.includes(conversation.id) ? 1 : 0}
                    onClick={() => {
                      onClickChatItem(conversation)
                    }}
                  />
                )}
              />
            </InfiniteScroll>
          </If>
        </div>
      </div>
    </>
  );
};

export default ChatMessagesListComponent;
